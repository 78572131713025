import React, { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import {
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
import { Backdrop, CircularProgress } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import UserProfilesService from "../../Libs/Services/UserProfiles.service";
import { AuthContext } from "../../Libs/Contexts";
import { IProfile } from "../../Libs/Models/IProfile.model";

import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";

interface IProps {
  loginCallback: () => void;
  logoutCallback: () => void;
}

export const Auth = (props: IProps) => {
  const navigate = useNavigate();
  const MySwal = withReactContent(Swal);
  const [inputs, setInputs] = useState({
    username: "",
    password: "",
    showPassword: false,
  });

  const isLogin = useContext(AuthContext);
  const [isOpenBackdrop, setOpenBackdrop] = useState(true);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
      setOpenBackdrop(false);
    }, 1000);
  }, []);

  useEffect(() => {
    if (isLogin) {
      navigate("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLogin]);

  //--------------------------------------------------//
  //--------------------------------------------------//
  const handleChange = (event: any) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleClickShowPassword = () => {
    setInputs({
      ...inputs,
      showPassword: !inputs.showPassword,
    });
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  //--------------------------------------------------//
  ///// Login /////
  const handleSubmit = (event: any) => {
    event.preventDefault();
    setOpenBackdrop(true);
    //login
    UserProfilesService.login(inputs.username, inputs.password)
      .then((res: IProfile) => {
        //get profile
        /* UserProfilesService
          .getProfile(localStorage.getItem('userId'))
          .then((res: IProfile) => {
            localStorage.setItem("muId", res.muId);
            localStorage.setItem("username", res.username);
            localStorage.setItem("email", res.email);
            localStorage.setItem("fname", res.fname);
            localStorage.setItem("lname", res.lname);
            onLoginComplete();
          })
          .catch(e => onLoginError("ไม่พบข้อมูล")); */
          onLoginComplete();
      })
      .catch((e) => onLoginError(e));
  };

  const onLoginComplete = () => {
    setOpenBackdrop(false);
    MySwal.fire({
      html: <i>{"สำเร็จ"}</i>,
      icon: "success",
    }).then((v) => {
      props.loginCallback();
    });
  }

  const onLoginError = (e: any) => {
    setOpenBackdrop(false);
    MySwal.fire({
      html: <i>{e}</i>,
      icon: "error",
    }).then((v) => {
      props.logoutCallback();
    });
  }

  //--------------------------------------------------//
  ///// Forget Password /////
  const handleForgetPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setOpenBackdrop(true);
    UserProfilesService.forgotPassword(inputs.username)
      .then((res) => {
        setOpenBackdrop(false);
        MySwal.fire({
          html: <i>{res}</i>,
          icon: "success",
        });
      })
      .catch((e) => {
        setOpenBackdrop(false);
        MySwal.fire({
          html: <i>{e}</i>,
          icon: "error",
        });
      });
  };

  //--------------------------------------------------//
  //--------------------------------------------------//
  return (
    <>
      {!isLoading ? (
        <div className="Auth-body">
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={isOpenBackdrop}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
          <div className="Auth-bar">
            <div style={{ display: "flex" }}>
              <Typography variant="h6" color="primary" sx={{ flexGrow: 1 }}>
              Ortho app
              </Typography>
              <Typography variant="h6" color="primary">
                ADMIN
              </Typography>
            </div>
            <div className="a"></div>
          </div>
          <div className="Auth-dialog">
            <Container component="main" maxWidth="xs">
              <Box
                sx={{
                  marginTop: 8,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Box
                  component="form"
                  onSubmit={handleSubmit}
                  noValidate
                  sx={{ mt: 1 }}
                >
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="username"
                    label="USERNAME"
                    name="username"
                    autoComplete="username"
                    autoFocus
                    value={inputs.username || ""}
                    onChange={handleChange}
                  />
                  <FormControl
                    sx={{ mt: 1 }}
                    variant="outlined"
                    required
                    fullWidth
                  >
                    <InputLabel htmlFor="password">PASSWORD</InputLabel>
                    <OutlinedInput
                      id="password"
                      name="password"
                      type={inputs.showPassword ? "text" : "password"}
                      value={inputs.password || ""}
                      onChange={handleChange}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                            color="primary"
                          >
                            {inputs.showPassword ? (
                              <VisibilityOff />
                            ) : (
                              <Visibility />
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                      label="Password"
                    />
                  </FormControl>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2, color: "#fff" }}
                  >
                    LOG IN
                  </Button>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                  >
                    <Button
                      variant="outlined"
                      size="small"
                      onClick={handleForgetPassword}
                    >
                      ลืมรหัสผ่าน
                    </Button>
                  </div>
                </Box>
              </Box>
            </Container>
          </div>
        </div>
      ) : (
        <div className="Auth-body"></div>
      )}
    </>
  );
};
