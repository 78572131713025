import React, { useState, useEffect } from "react";
import { Auth } from "./Auth";
import { StudentList, Situation, DetailList } from "./Pages/index";

import { BrowserRouter, Routes, Route } from "react-router-dom";

import Layout from "./Layout";
import LyInfo from "./Layout/LyInfo";
import { AuthContext } from "../Libs/Contexts";
import UserProfilesService from "../Libs/Services/UserProfiles.service";
import { IProfile } from '../Libs/Models/IProfile.model';

function App() {
  const [isLogin, setIsLogin] = useState(false);

  useEffect(() => {
    setIsLogin(UserProfilesService.isUserLogin());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localStorage.accessToken]);

  useEffect(() => {
    if (isLogin) {
      UserProfilesService
        .getProfile(localStorage.getItem('userId'))
        .then((res: IProfile) => {
          localStorage.setItem("muId", res.muId);
          localStorage.setItem("username", res.username);
          localStorage.setItem("email", res.email);
          localStorage.setItem("fname", res.fname);
          localStorage.setItem("lname", res.lname);
        })
      //.catch(e => onLoginError("ไม่พบข้อมูล"));
    }
  }, [isLogin]);

  //--------------------------------------------------//
  const login = () => {
    setIsLogin(UserProfilesService.isUserLogin());
  };

  const logout = () => {
    UserProfilesService.logout();
    setIsLogin(UserProfilesService.isUserLogin());
  };

  //--------------------------------------------------//
  //--------------------------------------------------//
  return (
    <BrowserRouter basename={"/"}>
      <AuthContext.Provider value={isLogin}>
        <Routes>
          <Route
            path="/login"
            element={<Auth loginCallback={login} logoutCallback={logout} />}
          />
          <Route element={<Layout logoutCallback={logout} />}>
            <Route path="/" element={<StudentList logoutCallback={logout} />} />
            <Route element={<LyInfo />}>
              <Route
                path="/:scenario/:userId"
                element={<Situation logoutCallback={logout} />}
              />
              <Route
                path="/:scenario/:userId/report"
                element={<DetailList />}
              />
            </Route>
          </Route>
        </Routes>
      </AuthContext.Provider>
    </BrowserRouter>
  );
}

export default App;
