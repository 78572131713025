import { Box, Typography } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";

import { TableList } from "./table";
import { PPagination } from "../../Common/PPagination";

import { RootStore } from "../../../Libs/Redux";
import { getMenu } from "../../../Libs/Modules/Menu";
import ReportsService from "../../../Libs/Services/Reports.service";
import { ISituation } from "../../../Libs/Models/IReport.model";
import StudentAction from "../../../Libs/Redux/Actions/Student.action";
import { PPage } from '../../../Libs/Constants/size';

interface IProps {
  logoutCallback?: () => void;
}

export function Situation(props: IProps) {
  const student = useSelector((state: RootStore) => state.student);
  const { scenario/* , userId */ } = useParams();

  const [rowsPerPage, setRowsPerPage] = useState(PPage);
  const [curPage, setCurPage] = useState(1);
  const menu = getMenu(scenario);

  const [dataFocus, setDataFocus] = useState<ISituation[]>([]);
  const [dataAll, setDataAll] = useState<ISituation[]>([]);
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scenario]);

  useEffect(() => {
    const _d_ = dataAll.filter(
      (item: ISituation) => item.name === scenario?.toLocaleLowerCase()
    );
    setDataFocus(_d_);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataAll]);

  async function fetchData() {
    //scenario => scenario1, scenario2, scenario3, pretest, posttest, scenarioAll
    ReportsService.findSitulation(student.info.userId, scenario ? scenario : '')
      .then((res) => { setDataAll(res); })
      .catch((e) => props.logoutCallback && props.logoutCallback());
  }

  //--------------------------------------------------//
  //--------------------------------------------------//
  const handleChangeRowsPerPage = (value: string) => {
    setRowsPerPage(Number(value));
    setCurPage(1);
  };

  const handleChangePage = (value: number) => {
    setCurPage(value);
  };

  const selectDetail = (id: number, row: ISituation) => {
    dispatch(StudentAction.setReport({ index: id, situlation: dataFocus }));
    navigate(location.pathname + "/report");
  };
  //--------------------------------------------------//
  //--------------------------------------------------//
  return (
    <Box id="detail" sx={{ px: 2, pb: 2 }}>
      <div className="dis-flex flex-row flex-m m-t-10 m-b-10">
        <Typography variant="body2" color="primary" sx={{ pr: 2 }}>
          {`ข้อมูลการทำแบบทดสอบ`}
        </Typography>
        <Typography variant="h5" color="secondary">
          {menu.label}
        </Typography>
      </div>
      <TableList
        data={dataFocus.slice(
          (curPage - 1) * rowsPerPage,
          (curPage - 1) * rowsPerPage + rowsPerPage
        )}
        onClick={selectDetail}
      />
      <div className="p-t-20">
        <PPagination
          quantity={dataFocus.length}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          onChagePage={handleChangePage}
        />
      </div>
    </Box>
  );
}
