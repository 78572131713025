import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";
import SystemUpdateAltIcon from "@mui/icons-material/SystemUpdateAlt";

import HTable, { IHeadTabel } from "../../Common/HTable";

import { ISituation } from "../../../Libs/Models/IReport.model";

const headCells: IHeadTabel[] = [
  {
    id: "id",
    label: "ครั้งที่",
    align: "center",
    sort: false,
    width: 80,
  },
  { id: "score", label: "คะแนน", align: "center", width: 100 },
  { id: "report", label: "REPORT", align: "center", width: 100 },
  { id: "end", label: "", align: "left", minWidth: 580 },
];

interface IProps {
  data: ISituation[];
  onClick: (id: number, row: ISituation) => void;
}

export function TableList(props: IProps) {
  //--------------------------------------------------//
  //--------------------------------------------------//
  return (
    <TableContainer component={Paper} elevation={0}>
      <Table size="small" sx={{ width: "100%" }} aria-label="customized table">
        <HTable cells={headCells} />
        <TableBody>
          {props.data.map((row: ISituation, index: number) => (
            <TableRow key={index}>
              <TableCell align="center">{index + 1}</TableCell>
              <TableCell align="center">{`${row.score}`}</TableCell>
              <TableCell align="center">
                <Button
                  variant="contained"
                  onClick={() => props.onClick(index + 1, row)}
                >
                  <SystemUpdateAltIcon fontSize="small" />
                </Button>
              </TableCell>
              <TableCell align="center"></TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
