import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Alert,
  Box,
  Divider,
  Drawer,
  FormControl,
  ListItemButton,
  ListItemText,
  RadioGroup,
  Snackbar,
  Toolbar,
  Typography,
} from "@mui/material";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";

import { drawerWidth } from "../../Libs/Constants/size";
import { primary } from "../../Styles/theme";

export default function Slidebar() {
  const navigate = useNavigate();
  let { userId, scenario } = useParams();
  const [open, setOpen] = React.useState(false);

  //--------------------------------------------------//
  //--------------------------------------------------//
  const handleRadioChange = (val: string) => {
    if (userId !== undefined) {
      navigate(`/${val}/` + userId);
    } else {
      setOpen(true);
    }
  };

  //--------------------------------------------------//
  //--------------------------------------------------//
  return (
    <Drawer
      variant="permanent"
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: "border-box" },
      }}
    >
      <Toolbar variant="dense" />
      <Box sx={{ overflow: "auto", pl: 2, overflowX: "hidden" }}>
        <Typography variant="h6" className="mt-4">
          SUBJECT
        </Typography>
        <ListItemButton
          dense
          disableGutters
          sx={{ pl: 2 }}
          onClick={() => navigate("/")}
        >
          <ListItemText
            primary={
              <Typography variant="h5" color="primary" sx={{ height: 20 }}>
                นักศึกษา
              </Typography>
            }
          />
          <ArrowLeftIcon
            color="primary"
            fontSize="large"
            sx={{ position: "absolute", right: -15 }}
          />
        </ListItemButton>
        <Divider color={primary} />

        <FormControl fullWidth sx={{ mt: 5 }} margin="normal">
          <RadioGroup defaultValue={0} name="radio-buttons-group3">
            <ListItemButton
              dense
              onClick={() => handleRadioChange("scenario1")}
            >
              <Typography
                variant="body1"
                color={scenario === "scenario1" ? "primary" : "secondary"}
              >
                SCENARIO #1
              </Typography>
            </ListItemButton>
            <Divider color={scenario === "scenario1" ? primary : "#fff"} />

            <ListItemButton
              dense
              onClick={() => handleRadioChange("scenario2")}
            >
              <Typography
                variant="body1"
                color={scenario === "scenario2" ? "primary" : "secondary"}
              >
                SCENARIO #2
              </Typography>
            </ListItemButton>
            <Divider color={scenario === "scenario2" ? primary : "#fff"} />

            <ListItemButton
              dense
              onClick={() => handleRadioChange("scenario3")}
            >
              <Typography
                variant="body1"
                color={scenario === "scenario3" ? "primary" : "secondary"}
              >
                SCENARIO #3
              </Typography>
            </ListItemButton>
            <Divider color={scenario === "scenario3" ? primary : "#fff"} />
          </RadioGroup>
        </FormControl>

        <Snackbar
          open={open}
          autoHideDuration={5000}
          onClose={() => setOpen(false)}
        >
          <Alert
            onClose={() => setOpen(false)}
            severity="warning"
            sx={{ width: "100%", mb:7 }}
          >
            กรุณาเลือกนักศึกษา!
          </Alert>
        </Snackbar>
      </Box>
    </Drawer>
  );
}
