import env from "./env";
import { HttpClient } from "./httpClient";
import { IReport, ISituation } from "../Models/IReport.model";

export interface IReportsService {
  findReports: () => Promise<IReport[]>;
  findSitulation: (id: number, type: string) => Promise<ISituation[]>;
}

class ReportsService extends HttpClient implements IReportsService {
  /* constructor() {
    super();
  } */

  public async findReports(): Promise<IReport[]> {
    try {
      const url = `${env.APP_API_HOST}/report-app3/report`;
      const response = await this.get(url);
      if (!response.data) {
        throw new Error("ไม่พบข้อมูล");
      }
      const data: any = response.data;
      if (data.status === "error") {
        throw new Error("ไม่พบข้อมูล");
      }
      return response.data as IReport[];
    } catch (e) {
      throw e;
    }
  }

  public async findSitulation(userId: number, type: string): Promise<ISituation[]> {
    try {
      const url = `${env.APP_API_HOST}/report-app3/scenario/${userId}?type=${type}`;
      const response = await this.get(url);
      if (!response.data) {
        throw new Error("ไม่พบข้อมูล");
      }
      const data: any = response.data;
      if (data.status === "error") {
        throw new Error("ไม่พบข้อมูล");
      }
      return response.data as ISituation[];
    } catch (e) {
      throw e;
    }
  }
}

export default new ReportsService();
