export function getMenu(param: string | undefined) {
  switch (param) {
    case "scenario2":
      return {
        label: "SCENARIO 2",
        report: "scenario2",
      };
    case "scenario3":
      return {
        label: "SCENARIO 3",
        report: "scenario3",
      };
    case "scenario1":
    default:
      return {
        label: "SCENARIO 1",
        report: "scenario1",
      };
  }
}
